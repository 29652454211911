.counter {

    padding-left: 24px;
    min-height: 140px;
    margin-top: 0px !important;

    & .title {
        @extend .text-size-40;
        @extend .uppercase;
        font-weight: bold;
        letter-spacing: -1px;
        line-height: 48px;
        color: #484848;
    }

    & .count {
        opacity: 0.2;
        color: #484848;
        font-size: 140px;
        font-weight: bold;
        letter-spacing: -3.5px;
        line-height: 168px;
        text-align: right;
    }
}