@import '../helpers/variables';

.card-list-item {
    background-color: $white-color;
    box-shadow: 3px 3px 2px 0 rgba(0,0,0,0.05);
    min-height: 90px;
    padding: 16px;
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 8px;

    & .title {
        @extend .text-size-28;
        @extend .text-dark-gray;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 6px;
    }

    & .brand {
        @extend .text-size-14;
        @extend .capitalize;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        color: #757575;
    }

    & .cast {
        @extend .brand;
    }
    & span {
        @extend .brand;
        margin-left: 16px;
        margin-right: 16px;
    }

    & .status {
        @extend .text-white;
        @extend .text-size-12;
        @extend .capitalize;
        font-weight: 600;
        letter-spacing: -0.2px;
        line-height: 18px;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 2px;
    }
}