@font-face {
    font-family: "avant-garde-light";
    font-style: normal;
    font-weight: lighter !important;
    letter-spacing: 0.5px !important;
    src: url("../../fonts/avant-garde/ITCAvantGardeStd-XLtCn.otf");
}

@font-face {
    font-family: "avant-garde-medium";
    font-style: normal;
    font-weight: 300 !important;
    letter-spacing: 0.5px !important;
    src: url("../../fonts/avant-garde/ITCAvantGardeStd-MdObl.otf");
}

@font-face {
    font-family: "avant-garde-semi-bold";
    font-style: normal;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    src: url("../../fonts/avant-garde//ITCAvantGardeStd-DemiObl.otf");
}

@font-face {
    font-family: "avant-garde-bold";
    font-style: normal;
    font-weight: bold !important;
    letter-spacing: 0.5px !important;
    src: url("../../fonts/avant-garde/ITCAvantGardeStd-BoldObl.otf");
}