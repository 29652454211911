.actors-screen {
  background: rgb(220, 219, 219);
  background: linear-gradient(
    90deg,
    rgba(220, 219, 219, 1) 0%,
    rgba(245, 245, 245, 1) 49%,
    rgba(220, 219, 219, 1) 100%
  );
  padding-bottom: 50px;
}

.actors-list-container {
  background-color: #d5d4d4;
  width: 100%;
  z-index: 10;
  margin-top: 20px;
}

.actors-list-header {
  width: 100%;
  height: 72px;
  background-color: #cecece;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.actors-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 5%;
  padding-right: 5%;
}

.selection-popup {
  background-color: black;
  width: 100vw;
  height: 72px;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  bottom: 0;
  z-index: 999;
}
.selection-popup p {
  color: white;
  font-size: 16px;
}

.sort-button {
  border-width: 1px;
  border-color: #a6a7ad;
  height: 42px;
  width: 42px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selection-popup .left {
  display: flex;
  flexdirection: row;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}

.popup-icon-container {
  display: flex;
  flex-direction: row;
}

.popup-icon {
  border-width: 1px;
  border-color: #a6a7ad;
  border-radius: 4px;
  width: 42px;
  height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 0px 10px;
  cursor: pointer;
}

.actors-list-header select {
  width: 15vw;
  border-color: #a6a7ad;
}

.actors-list-header .searchbox {
  width: 15vw;
  border-color: #a6a7ad;
}
