.navbar {

    height: 64px;
    background: linear-gradient(270deg, #282828 0%, #393939 53.44%, #282828 100%);
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;

    & .logo {
        width: 95.12px;
        height: 40px;
    }
    & ul.nav-list {
        & a.nav-item {
            margin-left: 8px !important;
            margin-right: 8px;
            padding-left: 8px !important;
            padding-right: 8px;
            font-size: 13px;
            line-height: 12px;
            @extend .text-gray;
            font-weight: bold;
            text-transform: capitalize;
            letter-spacing: 0.59px;

            &:active {
                color: #EAE8E8 !important;
            }
        }
    }

    & .searchbar {
        height: 40px;
        max-width: 253px;
        border: 1px solid #5B5B5B;
        border-radius: 4px;
        color: #FFFFFF;
    }

    & .notifications {
        box-sizing: border-box;
        height: 41px;
        width: 41px;
        border: 1px solid #505052;
        border-radius: 4px;
        background-color: #505052;

        & p {
            height: 23px;
            width: 10px;
            color: #FFFFFF;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 25px;
            align-self: center;
            margin-top: 8px;
            @extend .text-poppins-regular;
        }
    }

    & .profile {
        border: 1px solid #5B5B5B;
        border-radius: 4px;
        height: 40px;
        width: 40px;
    }
}