@import '../helpers/variables';

.self-end {
    align-self: flex-end;
}

.status-bg {
    background-image: url('#{$image-path}/label-border.svg');
    background-size: contain;
    background-repeat: no-repeat;
    min-width: fit-content;
    min-height: 24px;
}

.primary-bg {
    background: linear-gradient(90deg, #DCDBDB 0%, #F5F5F5 48.25%, #D0D0D0 99.04%);
}

.secondary-bg {
    background-color: #D3D3D3;
}