.actor-preview-container {
  width: 164px;
  min-width: 164px;
  height: 218px;
  cursor: pointer;
  border-width: 1px;
  border-color: black;
  border-radius: 10px;
  z-index: 2;
  background-color: white;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  margin: 20px;
}

.actor-preview-container img {
  width: 100%;
  height: 70%;
  z-index: 1;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.actor-preview-container h1 {
  color: #4a4a4a;
  font-size: 16px;
  font-family: "poppins-bold";
}

.actor-preview-container h2 {
  font-family: "poppins-semi-bold";
  font-size: 12px;
  color: #4a4a4a;
}

.actor-preview-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.actor-image {
  width: 100%;
  height: 100%;
}

.actor-preview-container .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 164px;
  // position: absolute;
  z-index: 10;
}

.favouriteIcon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 15px;
}

.actor-preview-container .actor-role-container {
  background-color: #f2f2f2;
  transform: rotateZ(-90deg);
  margin-left: -23px;
  margin-top: 23px;
  border-top-right-radius: 10px;
}
.actor-preview-container .actor-role-container p {
  font-family: "poppins-semi-bold";
  font-size: 10px;
  color: #757575;
  padding: 5px;
}

.checkbox {
  height: 20px;
  width: 20px;
  accent-color: black;
}
