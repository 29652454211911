$gray-color         : #A6A7AD;
$light-gray-color   : #EAE8E8;
$dark-gray-color    : #484848;
$white-color        : #FFFFFF;

$primary-color      : $gray-color;

//Image path
$image-path         : '../../assets';

$colors:
    "gray"          $gray-color,
    "light-gray"    $light-gray-color,
    "dark-gray"     $dark-gray-color,
    "white"         $white-color,
    "primary"       $primary-color;

// font size variables
$font-sizes:
    "10" 10px,
    "12" 12px,
    "14" 14px,
    "16" 16px,
    "18" 18px,
    "20" 20px,
    "22" 22px,
    "24" 24px,
    "28" 28px,
    "32" 32px,
    "38" 38px,
    "40" 40px;

$text-transform:
    lowercase,
    capitalize,
    uppercase;

$font-weights:
    "poppins-light",
    "poppins-regular",
    "poppins-medium",
    "poppins-semi-bold",
    "poppins-bold",
    "poppins-extra-bold",
    "avant-garde-light",
    "avant-garde-medium",
    "avant-garde-semi-bold",
    "avant-garde-bold";