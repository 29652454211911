@font-face {
    font-family: "poppins-light";
    font-style: normal;
    font-weight: lighter !important;
    letter-spacing: 0.5px !important;
    src: url("../../fonts/poppins/Poppins-Light.ttf");
}

@font-face {
    font-family: "poppins-regular";
    font-style: normal;
    font-weight: 300 !important;
    letter-spacing: 0.5px !important;
    src: url("../../fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
    font-family: "poppins-medium";
    font-style: normal;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    src: url("../../fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
    font-family: "poppins-semi-bold";
    font-style: normal;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    src: url("../../fonts/poppins/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "poppins-bold";
    font-style: normal;
    font-weight: bold !important;
    letter-spacing: 0.5px !important;
    src: url("../../fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
    font-family: "poppins-extra-bold";
    font-style: normal;
    font-weight: bolder !important;
    letter-spacing: 0.5px !important;
    src: url("../../fonts/poppins/Poppins-ExtraBold.ttf");
}