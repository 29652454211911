@import '../helpers/variables';

@each $name, $color in $colors {
    .text-#{$name} {
        color: $color;
    }
}

@each $name, $size in $font-sizes {
    .text-size-#{$name} {
        font-size: $size !important;
    }
}

@each $transform in $text-transform {
    .#{$transform} {
        text-transform: $transform !important;
    }
}

@each $weight in $font-weights {
    .text-#{$weight} {
        font-family: $weight !important;
    }
}

.text-light-gray {
    color: #EAE8E8 !important;
}

.page-title {
    @extend .text-dark-gray;
    @extend .capitalize;
    font-size: 140px;
    font-weight: bold;
    letter-spacing: -4px;
    line-height: 140px;
    margin-top: 16px;
}

.title-divider {

    margin-top: 16px;
    margin-bottom: 16px;

    & p {
        @extend .text-size-16;
        @extend .text-dark-gray;
        @extend .capitalize;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 5px;
    }
    & hr {
        box-sizing: border-box;
        height: 1px;
        width: 100%;
        border: 2px solid $dark-gray-color;
    }
}